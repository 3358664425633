#system-alert-bar {
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-primary);
  color: #fff;
  border-bottom: var(--border-primary);
  padding: var(--size-medium);
  overflow: hidden;
}
