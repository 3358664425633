.profile-page-head {
  background-color: var(--background-primary);
  padding: var(--size-medium);
  padding-bottom: 0;
  border-bottom: var(--border-primary);

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--background-primary);
    padding-bottom: var(--size-small);
  }

  &__back {
    margin-right: var(--size-medium);

    &-btn {
      path {
        fill: var(--body-primary);
      }
    }
  }

  &__title {
    &-wrap {
      display: flex;
      flex-direction: column;
    }
  }
}
