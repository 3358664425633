@import '../mixins';

.cookie-table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  border-radius: 8px;
  background-color: var(--background-tertiary);
  overflow: hidden;

  &__wrapper {
    flex: 1 1 auto;
    max-height: 200px;

    @extend %custom-scroll-y;
  }

  &__row {
    display: flex;
    align-items: center;
    padding: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-color-primary);
    }

    &--head {
      text-transform: uppercase;
      border-bottom: 1px solid var(--border-color-primary);
      color: var(--body-tertiary);
    }
  }

  &__cell {
    position: relative;
    overflow: hidden;
    padding-right: 4px;

    &--name {
      width: 20%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      word-break: break-all;

      @include mobile-and-tablet {
        width: 58%;
      }
    }

    &--provider {
      width: 20%;

      @include mobile-and-tablet {
        width: 25%;
      }
    }

    &--expiration {
      width: 15%;

      @include mobile-and-tablet {
        width: 25%;
      }
    }

    &--description {
      width: 45%;

      @include mobile-and-tablet {
        display: none;
      }
    }
  }
}
